export const ubuntuDesktop = {
        name: "Ubuntu Desktop",
        ide2: "ISO:iso/ubuntu-20.04.3-desktop-amd64.iso,media=cdrom",
        pool: "",
        ostype: "l26",
        scsihw: "virtio-scsi-pci",
        sockets: "1",
        sata0: "PoolAlumnat:20",
        cores: "4",
        memory: "4096",
        net0: "",
        balloon: "1024",
        vga: "type=std,memory=32",
};

export const windowsServer = {
        name: "Windows Server 2019",
        ide2: "ISO:iso/17763.737.190906-2324.rs5_release_svc_refresh_SERVER_EVAL_x64FRE_es-es_1.iso,media=cdrom",
        pool: "",
        ostype: "win10",
        scsihw: "virtio-scsi-pci",
        sockets: "1",
        sata0: "PoolAlumnat:30",
        cores: "4",
        memory: "4096",
        net0: "",
        balloon: "1024",
        vga: "type=std,memory=32",
};

export const windows10 = {
        name: "Windows 10",
        ide2: "ISO:iso/Win10_21H2_Spanish_x64.iso,media=cdrom",
        pool: "",
        ostype: "win10",
        scsihw: "virtio-scsi-pci",
        sockets: "1",
        sata0: "PoolAlumnat:30",
        cores: "4",
        memory: "8192",
        net0: "",
        balloon: "1024",
        vga: "type=std,memory=32",
};


export const pfSense = {
        name: "Pfsense",
        ide2: "ISO:iso/pfSense-CE-2.5.2-RELEASE-amd64.iso,media=cdrom",
        pool: "",
        ostype: "l26",
        scsihw: "virtio-scsi-pci",
        sockets: "1",
        sata0: "PoolAlumnat:12",
        cores: "4",
        memory: "2048",
        net0: "",
        balloon: "1024",
        vga: "type=std,memory=32",
};

export const ubuntuServer = {
        name: "Ubuntu Server",
        ide2: "ISO:iso/ubuntu-20.04.3-live-server-amd64.iso,media=cdrom",
        pool: "",
        ostype: "l26",
        scsihw: "virtio-scsi-pci",
        sockets: "1",
        sata0: "PoolAlumnat:20",
        cores: "4",
        memory: "2048",
        net0: "",
        balloon: "1024",
        vga: "type=std,memory=32",
};
